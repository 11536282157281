/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-component-dashs-account-info-main {
  /* border-radius: 5px; */
  margin: auto;
  background-color: #fff;
  display: flex;
  overflow: auto;
  padding: 30px 30px 10px;
  min-height: 70vh;
}
.antd-pro-component-dashs-account-info-main .antd-pro-component-dashs-account-info-leftmenu {
  width: 20%;
}
.antd-pro-component-dashs-account-info-main .antd-pro-component-dashs-account-info-leftmenu .ant-menu-item {
  font-weight: 500;
  background: #F6F6F6;
  color: #333333;
  border: unset;
  height: auto;
  padding: 5px 10px;
  text-transform: uppercase;
  margin-right: 10px;
}
.antd-pro-component-dashs-account-info-main .antd-pro-component-dashs-account-info-leftmenu .ant-menu-inline {
  border: none;
}
.antd-pro-component-dashs-account-info-main .antd-pro-component-dashs-account-info-leftmenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #EDEDED;
  color: #333333;
}
.antd-pro-component-dashs-account-info-main .antd-pro-component-dashs-account-info-right {
  flex: 1;
  padding-left: 60px;
  padding-right: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.antd-pro-component-dashs-account-info-main .antd-pro-component-dashs-account-info-right .antd-pro-component-dashs-account-info-title {
  font-size: 25px;
  color: #333333;
  line-height: 28px;
  margin-bottom: 12px;
}
.antd-pro-component-dashs-account-info-main .ant-list-split .ant-list-item:last-child {
  border-bottom: 1px solid #e8e8e8;
}
.antd-pro-component-dashs-account-info-main .ant-list-item {
  padding-top: 14px;
  padding-bottom: 14px;
}
.ant-list-item-meta .taobao {
  color: #ff4000;
  display: block;
  font-size: 48px;
  line-height: 48px;
  border-radius: 2px;
}
.ant-list-item-meta .dingding {
  background-color: #2eabff;
  color: #fff;
  font-size: 32px;
  line-height: 32px;
  padding: 6px;
  margin: 2px;
  border-radius: 2px;
}
.ant-list-item-meta .alipay {
  color: #2eabff;
  font-size: 48px;
  line-height: 48px;
  border-radius: 2px;
}
font.strong {
  color: #52c41a;
}
font.medium {
  color: #faad14;
}
font.weak {
  color: #ff4d4f;
}
@media screen and (max-width: 768px) {
  .antd-pro-component-dashs-account-info-main {
    flex-direction: column;
    width: 100%;
    height: 77vh;
  }
  .antd-pro-component-dashs-account-info-main .antd-pro-component-dashs-account-info-leftmenu {
    width: 100%;
    border: none;
  }
  .antd-pro-component-dashs-account-info-main .antd-pro-component-dashs-account-info-right {
    padding: 10px;
  }
}
