.mapWrapper {
  height: 100vh;
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
}
.mapWrapper > .styleLeft {
  height: 100%;
  background: #F5F5F5;
  --left-width: 25%;
  width: var(--left-width);
  float: left;
  box-shadow: 3px 0px 6px #00000029;
  opacity: 1;
  position: relative;
  z-index: 1000;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow: -moz-hidden-unscrollable;
  scrollbar-width: 5px;
}
.mapWrapper > .styleLeft > .addReviewLayer {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 12px 15px;
  background-color: #fff;
  z-index: 1004;
}
.mapWrapper > .styleLeft > .addReviewLayer .addReviewLayer__header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.mapWrapper > .styleLeft > .addReviewLayer .addReviewLayer__header .buttonPost {
  border: none;
  font-weight: 500;
  color: #1e90ff;
  font-size: 18px;
  margin-left: 4px;
  border: 1px solid #1e90ff;
}
.mapWrapper > .styleLeft > .addReviewLayer .addReviewLayer__header .buttonPost:hover {
  background-color: #1e90ff;
  color: #fff;
}
.mapWrapper > .styleLeft > .addReviewLayer p {
  margin-bottom: 0px;
  margin-left: 15px;
}
.mapWrapper > .styleLeft > .addReviewLayer .addReviewLayer__body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mapWrapper > .styleLeft > .addReviewLayer .addReviewLayer__body .reviewRating {
  font-size: 28px;
  margin: 20px 0;
}
.mapWrapper > .styleLeft > .addReviewLayer .addReviewLayer__body .reviewTitle {
  border: none;
  margin-bottom: 20px;
  font-size: 16px;
}
.mapWrapper > .styleLeft > .addReviewLayer .addReviewLayer__body .reviewContent {
  border: none;
  font-size: 16px;
}
.mapWrapper > .styleLeft .styleLeft__head__login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 40px;
  border-radius: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.mapWrapper > .styleLeft .styleLeft__loginField_hide {
  max-height: 0px;
  transition: max-height 2s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.mapWrapper > .styleLeft .styleLeft__loginField_hide .loginBtn,
.mapWrapper > .styleLeft .styleLeft__loginField_hide .loginBtn__page {
  display: none;
}
.mapWrapper > .styleLeft .styleLeft__loginField {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-height: 50vh;
  transition: max-height 1s;
  overflow: hidden;
}
.mapWrapper > .styleLeft .styleLeft__loginField .loginBtn {
  margin: 10px 10px;
  width: 260px;
  height: 30px;
}
.mapWrapper > .styleLeft .styleLeft__loginField .loginBtn span {
  font-size: 16px;
  font-weight: 400;
}
.mapWrapper > .styleLeft .styleLeft__loginField .loginBtn img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.mapWrapper > .styleLeft .styleLeft__loginField .loginBtn__gg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #616161;
  height: 30px;
}
.mapWrapper > .styleLeft .styleLeft__loginField .loginBtn__fb {
  border: none;
  background-color: #3B5994;
  color: #fff;
  height: 30px;
  font-size: 16px;
}
.mapWrapper > .styleLeft > .shareContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 12px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.mapWrapper > .styleLeft > .shareContainer > .ant-col > p {
  margin: 0;
}
.mapWrapper > .styleLeft > .layer2Container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoImage {
  width: 100%;
  height: 240px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid #bbb;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoPlace {
  background-color: #fff;
  padding: 12px 24px;
  border-bottom: 1px solid #bbb;
  width: 100%;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoPlace > .layer2InfoPlace__rate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoPlace > p {
  margin: 0;
  line-height: 30px;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoPlace > p:first-child {
  letter-spacing: 0px;
  overflow: hidden;
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 500;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoPlace > p:nth-child(2) {
  color: #42464e;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButton {
  padding: 14px 10px;
  background-color: #fff;
  border-bottom: 1px solid #bbb;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButton > .ant-row {
  display: flex;
  flex-wrap: wrap;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButton > .ant-row > .ant-col > .styleBtn1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButton > .ant-row > .ant-col > .styleBtn1 > p {
  margin-bottom: 0;
  font-size: 12px;
  margin-top: 4px;
  color: #1a73e8;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButton > .ant-row > .ant-col > .styleBtn1 > img {
  cursor: pointer;
  width: 3.6rem;
  height: 3.6rem;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButtonFindPath {
  background-color: #4285f4;
  border-bottom: 1px solid #bbb;
  padding: 18px 24px;
  padding-bottom: 48px;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButtonFindPath > .ant-row > .ant-col > .tranportImage {
  width: 36px;
  height: 36px;
  background-color: #0429c4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  cursor: pointer;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButtonFindPath > .ant-row > .ant-col > .tranportImage > .tranportImageChil {
  background-size: contain;
  background-position: center;
  width: 17px;
  height: 22px;
  background-repeat: no-repeat;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButtonFindPath > .ant-row > .ant-col > .tranportImageSelected {
  background-color: #ffffff;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButtonFindPath > .ant-row > .ant-col > .tranportImageSelected > .tranportImageChil {
  filter: invert() sepia(0) saturate(0) hue-rotate(346deg) brightness(104%) contrast(97%);
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButtonFindPath > .ant-row > .ant-col > .svgWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButtonFindPath > .ant-row > .ant-col > .svgWrapper > svg {
  fill: #fff;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButtonFindPath > .ant-row > .ant-col > .svgWrapper > svg:first-child {
  fill: #4285f4;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButtonFindPath > .ant-row > .ant-col > .svgWrapper > svg > .stroke-1 {
  stroke-width: 1;
  stroke-linecap: butt;
  stroke-dasharray: 0;
  stroke: #fff;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButtonFindPath > .ant-row > .ant-col > .svgWrapper > img {
  margin-top: 5px;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButtonFindPath > .ant-row > .ant-col > .desParagraph {
  color: #fff;
  padding-left: 10px;
  font-size: 14px;
  border-bottom: 1px solid;
  margin-top: 8px;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoButtonFindPath > .ant-row > .ant-col > .confirmChoosePlace {
  color: #000000;
  padding-left: 10px;
  font-size: 14px;
  margin-top: 8px;
  width: 100%;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoContact {
  background-color: #fff;
  border-bottom: 1px solid #bbb;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoContact > .ant-row {
  padding: 8px 0;
  display: flex;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoContact > .ant-row:hover {
  background-color: #f1f3f4;
  cursor: pointer;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoContact > .ant-row:last-child {
  margin-bottom: 0px;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoContact > .ant-row > .ant-col-3 {
  text-align: center;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoContact > .ant-row > .ant-col-21 {
  color: #000008;
  font-size: 14px;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoDescription {
  padding: 12px 24px;
  background-color: #fff;
  border-bottom: 1px solid #bbb;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoDescription > .titleInfo {
  margin-bottom: 0;
  color: #16276d;
  font-weight: bold;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2ReviewTitle {
  padding: 12px 20px;
  margin-bottom: 0;
  font-weight: bold;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoReview {
  padding: 5px 24px;
  background: #fff;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoReview .review__title {
  margin-bottom: 0;
  color: #16276d;
  font-weight: bold;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoReview .review__user {
  margin-bottom: 5px;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoReview .review__info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoReview .review__info .info__rate {
  font-size: 14px;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoReview .review__info p {
  margin-bottom: 0;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoReview .review__time {
  font-size: 11px;
  color: #afa5a5;
}
.mapWrapper > .styleLeft > .layer2Container > .layer2InfoReview .review__content {
  font-size: 14px;
}
.mapWrapper > .styleLeft > .layer2Container .btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.mapWrapper > .styleLeft > .layer2Container .btnWrapper .btnAddReview {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.mapWrapper > .styleLeft > .layer2Container .ant-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.mapWrapper > .styleLeft > .layer2Container > .routesListWrapper > .routesItemInstruction {
  width: 100%;
  background-color: #fff;
  margin: 5px 0px;
}
.mapWrapper > .styleLeft > .layer2Container > .routesListWrapper > .routesItemInstruction > .instructionInfo {
  width: 100%;
  background-color: #fff;
  font-size: 14px;
  padding: 9px 0px;
}
.mapWrapper > .styleLeft > .layer2Container > .routesListWrapper > .routesItemInstruction > .instructionInfo > .instructionName {
  width: 80%;
  float: left;
  padding: 0px 15px 0px 0px;
  font-size: 16px;
}
.mapWrapper > .styleLeft > .layer2Container > .routesListWrapper > .routesItemInstruction > .instructionInfo > .instructionDistance {
  width: 20%;
  float: right;
  font-weight: 500;
}
.mapWrapper > .styleLeft > .layer2Container > .routesListWrapper > .routesItemInstruction > .instructionInfo > .instructionTime {
  width: 20%;
  float: right;
  color: #1e935b;
  font-weight: 500;
}
.mapWrapper > .styleLeft > .layer2Container > .routesListWrapper > .routesListItemWrapper {
  padding: 12px 24px;
}
.mapWrapper > .styleLeft > .layer2Container > .routesListWrapper > .routesListItemWrapper .routesListItemName {
  font-size: 15px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}
.mapWrapper > .styleLeft > .layer2Container > .routesListWrapper > .routesListItemWrapper .routesListItemName > p {
  font-size: 12px;
  font-style: italic;
  color: #1e935b;
  text-decoration: underline;
}
.mapWrapper > .styleLeft > .layer2Container > .routesListWrapper > .routesListItemWrapper .routesListItemInfo {
  margin-left: auto;
  text-align: right;
}
.mapWrapper > .styleLeft > .layer2Container > .routesListWrapper > .routesListItemWrapper .routesListItemTime {
  margin-bottom: 0;
}
.mapWrapper > .styleLeft > .layer2Container > .routesListWrapper > .routesListItemWrapper div > .instructionsImage {
  filter: invert() sepia(0) saturate(0) hue-rotate(346deg) brightness(104%) contrast(97%);
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.mapWrapper > .styleLeft > .layer3Container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  overflow-y: auto;
  background-color: #ffffff;
  z-index: 1001;
}
.mapWrapper > .styleLeft > .layer3Container .infoContainer {
  padding: 25px 24px;
  border-bottom: 1px solid #d9d9d9;
}
.mapWrapper > .styleLeft > .layer3Container .infoContainer p {
  margin-bottom: 0;
}
.mapWrapper > .styleLeft > .layer3Container .descriptionsContainer {
  padding: 12px 24px;
  border-bottom: 1px solid #d9d9d9;
}
.mapWrapper > .styleLeft > .layer3Container .descriptionsContainerLast {
  padding: 12px 24px;
  border-bottom: 1px solid #d9d9d9;
}
.mapWrapper > .styleLeft > .layer3Container .buttonField {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 94%;
}
.mapWrapper > .styleLeft > .layer3Container .buttonField .submitButton {
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.mapWrapper > .styleLeft > .layer3Container .buttonField .submitButton > span {
  padding: 5px 18px;
  background: #5890ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #00000000;
  border: 0.5px solid #5890ff;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
}
.mapWrapper > .styleLeft > .layer3Container .buttonField .backButton {
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 10px;
}
.mapWrapper > .styleLeft1 {
  padding: 0;
  overflow: -moz-hidden-unscrollable;
  scrollbar-width: 5px;
}
.mapWrapper > .styleRight {
  height: 100%;
  width: calc(100% - 25%);
  float: left;
}
.popUpMarker {
  margin-bottom: 40px;
}
.popUpMarker > .leaflet-popup-content-wrapper {
  background-color: transparent;
  box-shadow: none;
}
.popUpMarker > .leaflet-popup-tip-container > .leaflet-popup-tip {
  background-color: transparent;
  box-shadow: none;
}
.leaflet-top {
  z-index: 999;
}
.leaflet-control-container > .leaflet-right > .leaflet-routing-container {
  display: none;
}
.filterItemPlaceGroup {
  background-color: #fff;
}
.myLocationBtn {
  background-image: url('/static/web/images/map/iconfinder_ic_my_location_48px_352557.png');
}
.infoPanel {
  width: 380px;
  display: flex;
  background-color: #ffffff;
  position: absolute;
  bottom: 60px;
  z-index: 999;
  left: 50%;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d9d9d9;
  margin-left: -190px;
  cursor: context-menu;
}
.infoPanel .infoExit {
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
  z-index: 9;
}
.infoPanel > .ant-col > .titleInfoPanel {
  font-weight: bold;
  line-height: 21px;
}
.infoPanel > .ant-col > .descriptionInfoPanel {
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  height: 25px;
  overflow: hidden;
}
.infoPanel > .ant-col > p {
  font-size: 13px;
}
.infoPanel > .ant-col > .shareLocation {
  width: 40px;
  height: 40px;
  background-image: url(/static/web/images/share-square.png);
  overflow: hidden;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  background-repeat: no-repeat;
}
.leaflet-control-attribution {
  display: none;
}
.noneDisplayInfoPAnel {
  display: none;
}
.longDescription .ck {
  height: 200px;
}
.dropDownWeb {
  background-color: #fff;
  --left-width: 25%;
  width: var(--left-width) !important;
  left: 0px !important;
  top: 128px !important;
  height: 80vh !important;
}
.dropDownWeb .ant-select-dropdown-menu {
  padding-left: 0;
}
.pac-container {
  --left-width: 25%;
  width: var(--left-width) !important;
  left: 0px !important;
  border-top: none;
  box-shadow: none;
  top: 275px !important;
  z-index: 1003;
  padding: 10;
}
.pac-container .pac-item {
  padding: 0 20px;
  line-height: 35px;
}
.hdpi.pac-logo:after {
  background-image: unset !important;
}
.menu-user {
  position: relative;
  width: 260px;
  height: 30px;
  background-color: #fff;
  margin: 10px 10px;
  color: #616161;
  border-radius: 5px;
}
.menu-user__item {
  margin-bottom: 0;
}
.forgot-form .changePass__input {
  background: none;
  background-color: white;
  color: grey;
  font-size: 16px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid grey;
  margin: 25px 0;
}
.forgot-form .changePass__input input {
  border: none;
}
.forgot-form .changePass__input input:focus {
  border: none;
}
.instructionInfo {
  width: 100%;
  border-top: 1px solid #ededed;
  background-color: #fff;
  font-size: 14px;
  padding: 9px 0px;
}
.instructionInfo > .instructionName {
  width: 80%;
  float: left;
  padding: 0px 15px 0px 0px;
  font-size: 16px;
}
