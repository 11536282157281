#nprogress .bar {
  background: #4dbd74;
  height    : 4px;
}

.wrap-dashboard {
  min-height: calc(100vh - 94px);
  flex      : 1;
  min-width : 1px;
  margin    : 0 auto;
  padding   : 15px 2.5vw;
  background: #eee;
  overflow  : hidden auto;
}

.dashboardLeft {
  float         : left;
  width         : calc(100% - 320px);
  padding-bottom: 1rem;
}

.dashboard-custom .dashboardRight {
  float      : none;
  width      : auto;
  margin-left: calc(100% - 300px);
}

.dashboardBox {
  border-radius   : 5px;
  margin-bottom   : 1.5rem;
  background-color: #fff;
  background      : #fff;
  box-shadow      : 0 2px 5px rgb(0 0 0 / 8%);
}

.dashboardStatistic {
  position: relative;
}

.dashboardTitle {
  font-size     : 14px;
  font-weight   : 700;
  padding       : 10px;
  text-transform: uppercase;
  overflow      : hidden;
}

.ovh {
  overflow: hidden !important;
}

.dashboardStatistic li {
  position          : relative;
  color             : #999;
  font-size         : 17px;
  padding           : 0.6rem 0 0.6rem 70px;
  text-align        : left;
  display           : inline-block;
  height            : auto;
  vertical-align    : middle;
  min-height        : 72px;
  white-space       : nowrap;
  margin            : 0.4rem 2.5rem 0.8rem 0;
  max-width         : calc(100% / 5);
  -webkit-box-sizing: border-box;
  -moz-box-sizing   : border-box;
  box-sizing        : border-box;
}

.dashboard-custom .dashboardStatistic li {
  width: calc(100% / 4);
}

.dashboardStatistic li+li {
  border-left: 1px solid #d6d6d6;
}

.dashboard-custom .dashboardStatistic ul li.down {
  top: auto;
}

.dashboardStatistic li .dash_title {
  font-weight: 700;
  color      : #666;
  display    : inline-block;
  max-width  : 100%;
  font-size  : 15px;
}

.dashboardStatistic li .dash_icon {
  display              : inline-block;
  margin               : 0;
  background           : #4bac4d;
  text-align           : center;
  color                : #fff;
  line-height          : 40px;
  -webkit-border-radius: 100%;
  -moz-border-radius   : 100%;
  border-radius        : 100%;
  position             : absolute;
  top                  : 14px;
  left                 : 20px;
  width                : 40px;
  height               : 40px;
}

.dashboardStatistic li.customer .dash_icon {
  background-color: #18c0c2;
}

.dashboardStatistic li.down .dash_icon {
  background-color: #f94f5e;
}

.dashboardStatistic li .dash_icon i {
  font-size  : 16px;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 30%);
}

.dashboardStatistic li.total .number {
  color: #0094da;
}

.dashboardStatistic li.return .number {
  color: #ff8030;
}

.dashboardStatistic li.down .number {
  color: #f94f5e;
}

.dashboardStatistic li.customer .number {
  color: #18c0c2;
}

.dashboardStatistic li .number {
  display    : block;
  font-size  : 20px;
  line-height: 28px;
  font-weight: 600;
}

.dashboardStatistic li.customer .number {
  color: #18c0c2;
}

.dashboardStatistic li.down .number {
  color: #f94f5e;
}

.dashboardStatistic li .number span {
  vertical-align: middle;
}

.dashboardStatistic li .yesterday {
  display  : block;
  font-size: 14px;
}

.split {
  padding: 0 5px;
  display: inline-block;
}

.dashboardTitle i {
  color: #b6b6b6;
}

.txtBlue {
  color: #0094da;
}

.txtTN {
  text-transform: none !important;
}

.flr {
  float: right;
}

.fll {
  float: left;
}

.posR {
  position: relative;
}

.dashboard-right {
  background    : #fff;
  padding-bottom: 0;
  max-height    : 1479px;
  overflow      : hidden;
  flex-direction: column;
  border-radius : 5px;
  box-shadow    : 0 2px 5px rgb(0 0 0 / 8%);
}

.dashboard-right .dashboardTitle {
  padding-top   : 1.4rem;
  padding-bottom: 1.4rem;
  border-bottom : 1px solid #efefef;
  margin-bottom : 1.8rem;
}

.dashboard-right .dashboard-list {
  height  : 100%;
  overflow: auto;
  padding : 0 10px 0 20px;
}

.dash_icon {
  align-items          : center;
  display              : grid;
  width                : 28px;
  height               : 28px;
  -webkit-border-radius: 100%;
  -moz-border-radius   : 100%;
  border-radius        : 100%;
  font-size            : 15px;
  color                : #fff;
}

.kiem_hang {
  background-color: #4bac4d;
}

.ban_hang {
  background-color: #5ec6f2;
}

.nhan_tra_hang {
  background-color: #ff8030;
}

.nhap_hang {
  background-color: #ef5f81;
}

.tra_hang_nhap {
  background-color: #18c0c2;
}

.nhan_dat_hang {
  background-color: #ffdc30;
}

.form-dropdown .ant-select-selector {
  border       : unset !important;
  background   : #1887e84b !important;
  box-shadow   : unset !important;
  border-radius: 5px !important;
}