.antd-pro-component-dashs-upload-image-upload-message {
  line-height: 25px;
  height: 180px;
  border-radius: 50%;
  box-shadow: #333333 1px 1px 3px 0px;
  background-repeat: round;
  background-image: url('/static/web/images/uploadCamera.png');
}
.antd-pro-component-dashs-upload-image-upload-icon {
  font-size: 20px;
  padding: 5px;
  right: 20px;
  border-radius: 50px;
  z-index: 5;
  position: absolute;
  cursor: pointer;
  background: #eee;
  box-shadow: #333333 2px 0px 5px 0px;
}
.antd-pro-component-dashs-upload-image-upload-icon2 {
  font-size: 20px;
  padding: 5px;
  right: -15px;
  top: 90px;
  border-radius: 50px;
  z-index: 5;
  position: absolute;
  cursor: pointer;
  background: #eee;
  box-shadow: #333333 2px 0px 5px 0px;
}
.antd-pro-component-dashs-upload-image-upload-icon3 {
  font-size: 20px;
  padding: 5px;
  right: -10px;
  top: 40px;
  border-radius: 50px;
  z-index: 5;
  position: absolute;
  cursor: pointer;
  background: #eee;
  box-shadow: #333333 2px 0px 5px 0px;
}
.antd-pro-component-dashs-upload-image-upload-previewImage {
  background-color: #fff;
  position: absolute;
  width: 180px;
  border-radius: 50%;
  height: 180px;
  top: 0;
  left: 0;
}
.antd-pro-component-dashs-upload-image-upload-previewColor {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  position: absolute;
  width: 180px;
  border-radius: 50%;
  height: 180px;
  top: 0;
  left: 0;
}
.antd-pro-component-dashs-upload-image-upload-container {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9999;
  /* Sit on top */
  padding: 50px 0 50px 0;
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: #000000;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}
.antd-pro-component-dashs-upload-image-upload-container #crop-container-transform:hover #btnConfirmCropper {
  display: block !important;
}
.antd-pro-component-dashs-upload-image-upload-container #crop-container-insertLogo:hover #btnConfirmSave {
  display: block !important;
}
@media (min-width: 576px) {
  .antd-pro-component-dashs-upload-image-upload-container {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .antd-pro-component-dashs-upload-image-upload-container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .antd-pro-component-dashs-upload-image-upload-container {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .antd-pro-component-dashs-upload-image-upload-container {
    max-width: 100%;
  }
}
.antd-pro-component-dashs-upload-image-upload-container .antd-pro-component-dashs-upload-image-upload-imgContainer {
  margin-bottom: 1rem;
  min-height: 200px;
}
@media (min-width: 768px) {
  .antd-pro-component-dashs-upload-image-upload-container .antd-pro-component-dashs-upload-image-upload-imgContainer {
    min-height: 300px;
  }
}
.antd-pro-component-dashs-upload-image-upload-container .antd-pro-component-dashs-upload-image-upload-imgContainerLogo {
  min-height: 200px;
  text-align: center;
  position: relative;
  background: #000 url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}
@media (min-width: 768px) {
  .antd-pro-component-dashs-upload-image-upload-container .antd-pro-component-dashs-upload-image-upload-imgContainerLogo {
    min-height: 300px;
  }
}
.antd-pro-component-dashs-upload-image-upload-modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
