/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.baseView {
  display: flex;
  padding-top: 12px;
}
.baseView .ant-input,
.baseView .ant-picker,
.baseView .ant-input-number,
.baseView .ant-select-selector,
.baseView .ant-input-password {
  border: unset !important;
  box-shadow: unset !important;
  border-bottom: 1px solid #bababa !important;
  border-radius: unset !important;
}
.baseView .ant-input > .ant-input,
.baseView .ant-picker > .ant-input,
.baseView .ant-input-number > .ant-input,
.baseView .ant-select-selector > .ant-input,
.baseView .ant-input-password > .ant-input {
  border: unset !important;
}
.explaine {
  font-size: 13px;
  color: #848484;
}
.titleSpecial {
  font-size: 23px;
  color: #333333;
  margin: 30px 0 0px 0;
  line-height: 30px;
}
.tabCustom .ant-tabs-nav {
  margin: 0;
  margin-bottom: 1px;
  padding: 0 8px;
}
.tabCustomDesign .ant-tabs-nav {
  padding: 0;
}
.tabCustomDesign .ant-tabs-tab {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.77px;
  color: #222;
  text-transform: uppercase;
  margin-right: 5px;
  background-color: #f3f3f3;
  border: 1px solid #e6e6e6;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  padding: 10px 18px;
}
.tabCustomDesign .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #ffffff;
}
.tabCustomDesign .ant-tabs-ink-bar {
  display: none !important;
}
.tabCustomDesign .ant-table-filter-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .baseView {
    flex-direction: column;
  }
}
.securityView .ant-input,
.securityView .ant-picker,
.securityView .ant-input-number,
.securityView .ant-select-selector,
.securityView .ant-input-password {
  border: unset !important;
  box-shadow: unset !important;
  border-bottom: 1px solid #bababa !important;
  border-radius: unset !important;
}
.securityView .ant-input > .ant-input,
.securityView .ant-picker > .ant-input,
.securityView .ant-input-number > .ant-input,
.securityView .ant-select-selector > .ant-input,
.securityView .ant-input-password > .ant-input {
  border: unset !important;
}
