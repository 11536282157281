.containerPopup {
  min-width: 280px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
}
.containerPopup > .exitButton {
  position: absolute;
  top: 15px;
  right: 30px;
}
.containerPopup > .imagePopup {
  height: 90px;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
}
.containerPopup > .infoWrapper {
  padding: 10px;
  width: 100%;
  background-color: white;
}
.containerPopup > .infoWrapper > p {
  margin: 0;
  width: 181px;
  font-size: 16px;
}
.containerPopup > .infoWrapper > .nameInfo {
  color: #000000;
  font-weight: 500;
  width: 100%;
}
.containerPopup > .infoWrapper > .specializationsInfo {
  color: #70757a;
  font-size: 13px;
}
.containerPopup > .infoWrapper > .buttonDirection {
  cursor: pointer;
}
